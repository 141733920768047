import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

import Login from "@/views/Login.vue"
import AuthRedirect from "@/views/AuthRedirect.vue"

function checkRoutePermissions(routePermissions, userPermissions) {
  // console.log("Check Route Permissions", routePermissions, userPermissions)
  for (let i = 0; i < routePermissions.length; i++) {
    const routePermission = routePermissions[i]

    if (userPermissions.includes(routePermission) || routePermission === "*") {
      // console.log("User has permission")
      return true
    } else {
      // console.log("User does not have permission")
      return false
    }
  }
}

const requireAuthenticated = async (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"] && to.name !== "Login") {
    next({ name: "Login" })
  } 
  if (from.name !== "Login" || from.path !== "/login" || to.name !== "Logout") {
    // console.warn("Router Dispatch Refresh Token", "From", from.name, "To", to.name)
    // await store.dispatch("auth/refreshToken")
    const userPermissions = store.getters["auth/permissions"]
    const routePermissions = to.meta.permissions
    let hasPermission = checkRoutePermissions(routePermissions, userPermissions)
    if (hasPermission === false) {
      next({ name: "Unauthorized" })
    } else {
      next()
    }
  }
}

const redirectLogout = (to, from, next) => {
  store.dispatch("auth/logout").then(() => next("/login"))
}

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/auth-redirect",
    component: AuthRedirect,
    name: "AuthRedirect",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/dashboard/Home.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: () => import(/* webpackChunkName: "home" */ "@/views/general/MyProfile.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/customers/companies",
    name: "Company",
    component: () => import(/* webpackChunkName: "customer" */ "@/views/customer/CompanyHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/customers/company-detail",
    name: "CompanyDetail",
    component: () => import(/* webpackChunkName: "customer" */ "@/views/customer/CompanyDetail.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/customers/contacts",
    name: "Contact",
    component: () => import(/* webpackChunkName: "customer" */ "@/views/customer/ContactHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewContact"],
    },
  },
  {
    path: "/customers/sales-calls",
    name: "SalesCallHome",
    component: () => import(/* webpackChunkName: "customer" */ "@/views/customer/SalesCallHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewSalesCall"],
    },
  },
  {
    path: "/invoices",
    name: "InvoiceListHome",
    component: () => import(/* webpackChunkName: "sales" */ "@/views/customer/InvoiceListHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewSalesInvoices"],
    },
  },
  {
    path: "/products",
    name: "ProductHome",
    component: () => import(/* webpackChunkName: "product" */ "@/views/product/ProductHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewProducts"],
    },
  },
  {
    path: "/logistics",
    name: "LogisticsHome",
    component: () => import(/* webpackChunkName: "shipment" */ "@/views/product/LogisticsHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewLogistics"],
    },
  },
  {
    path: "/amazon",
    name: "AmazonHome",
    component: () => import(/* webpackChunkName: "amazon" */ "@/views/amazon/AmazonHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewLogistics"],
    },
  },
  {
    path: "/service/shipping",
    name: "ShippingHome",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/ShippingHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewShippingRecords"],
    },
  },
  {
    path: "/service/shipping/:id",
    name: "ShippingDetail",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/ShippingDetail.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewShippingRecords"],
    },
  },
  {
    path: "/service/receiving",
    name: "ReceivingHome",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/ReceivingHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReceivingRecords"],
    },
  },
  {
    path: "/service/receiving/:id",
    name: "ReceivingDetail",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/ReceivingDetail.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReceivingRecords"],
    },
  },
  {
    path: "/service/request-home",
    name: "ServiceHome",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/ServiceHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewServiceRequests"],
    },
  },
  {
    path: "/service/request-detail",
    name: "RequestDetail",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/RequestDetail.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewServiceRequests"],
    },
  },
  {
    path: "/pumps/home",
    name: "PumpHome",
    component: () => import(/* webpackChunkName: "pump" */ "@/views/service/PumpHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewDevices"],
    },
  },
  {
    path: "/pumps/depots",
    name: "PumpDepotHome",
    component: () => import(/* webpackChunkName: "pump" */ "@/views/service/PumpDepotHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewDeviceLocations"],
    },
  },
  {
    path: "/commissions/targets",
    name: "SalesTargets",
    component: () => import(/* webpackChunkName: "commissions" */ "@/views/sales/SalesTargets.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanCreateSalesCommission"],
    },
  },
  {
    path: "/commissions/reports",
    name: "CommissionReports",
    component: () => import(/* webpackChunkName: "commissions" */ "@/views/sales/CommissionReports.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewSalesCommissions"],
    },
  },
  {
    path: "/reports",
    name: "ReportsHome",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/ReportsHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/supplier-sales-history",
    name: "SupplierSalesHistory",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/SupplierSalesHistory.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/customer-sales-history",
    name: "CustomerSalesHistory",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/CustomerSalesHistory.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/customer-sales-detail",
    name: "CustomerSalesDetail",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/CustomerSalesDetail.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/customer-territory-list",
    name: "CustomerListReport",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/CustomerListReport.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/service-request-history",
    name: "ServiceRequestHistory",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/ServiceRequestHistory.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/rental-pump-report",
    name: "RentalPumpInvoicing",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/RentalPumpInvoicing.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/sales-performance-report",
    name: "SalesPerformanceReport",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/SalesPerformance.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/reports/depot-inventory-report",
    name: "DepotInventory",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/reports/DepotInventory.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewReports"],
    },
  },
  {
    path: "/admin/suppliers",
    name: "SupplierHome",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SupplierHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/territories",
    name: "TerritoryHome",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TerritoryHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/customer-types",
    name: "CustomerTypes",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/CustomerTypes.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/sales-call-types",
    name: "SalesCallTypes",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SalesCallTypes.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/price-lists",
    name: "PriceLists",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PriceList.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/users",
    name: "UserHome",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/UserHome.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/roles",
    name: "UserRoleHome",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/UserRole.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/admin/pump-types",
    name: "PumpTypes",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/PumpTypes.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["CanViewAdmin"],
    },
  },
  {
    path: "/release-notes",
    name: "Release Notes",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ReleaseNotes.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: redirectLogout,
    meta: {
      permissions: ["*"],
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import(/* webpackChunkName: "home" */ "@/views/general/Unauthorized.vue"),
    beforeEnter: requireAuthenticated,
    meta: {
      permissions: ["*"],
    },
  },
]

Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

// router.beforeResolve((to, from, next) => {
//   console.log("Router Before Resolve", "From", from.name, "To", to.name)
//   if (store.getters["auth/isAuthenticated"]) {
//   console.log("To Meta Permissions", to.meta.permissions)
//   let toMetaPermissions = to.meta.permissions
//   let userPermissions = store.getters["auth/permissions"]
//   if toMetaPermissions !== undefined {

//   console.log(userPermissions)
//   if (store.getters["auth/permissions"].includes(toMetaPermissions)) {
//     next()
//   } else {
//     next({ name: "Unauthorized" })
//   } 
// } else {
//   next()
// }
// })

export default router

import django_session from "@/api/django_session"
import axios from "axios"
import { PublicClientApplication } from "@azure/msal-browser"

const TOKEN_STORAGE_KEY = "MCP_TOKEN_STORAGE_KEY"
const clientId = process.env.VUE_APP_AZURE_CLIENT_ID
const authority = process.env.VUE_APP_AZURE_AUTHORITY
const redirectUri = process.env.VUE_APP_REDIRECT_URI
const postLogoutRedirectUri = process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI

const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
    postLogoutRedirectUri: postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
}

const state = {
  isAuthenticated: false,
  msAccessToken: "",
  azureUser: {},
  user: {},
  outlookToDoLists: [],
  roles: [],
  permissions: [],
  loginResponse: null,
  loginError: null,
  loginHint: "",
}

const msalInstance = new PublicClientApplication(msalConfig)
// Handle redirect promise
msalInstance.handleRedirectPromise().then((response) => {
  if (response && response.account) {
    localStorage.setItem("loginResponse", JSON.stringify(response.account))
    state.loginHint = response.account.idTokenClaims.login_hint
    msalInstance.setActiveAccount(response.account);
  }
}).catch((error) => {
    console.error(error);
});


const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
  outlookToDoListOptions: (state) => state.outlookToDoLists,
  userId: (state) => state.user.azure_client_id,
  roles: (state) => state.roles,
  permissions: (state) => state.permissions,
  loginError: (state) => state.loginError,
}

const actions = {
  async handleRedirect({ commit }) {
    let response, account
    try {
      const response = await msalInstance.handleRedirectPromise()
      if (response && response.account) {
        commit("SET_AZURE_USER", response.account)
        const account = msalInstance.setActiveAccount(response.account)

        // Try to acquire the token silently
        let tokenRequest = {
          scopes: ["https://graph.microsoft.com/.default"],
          account: account,
        }
        const accessTokenResponse = await msalInstance.acquireTokenSilent(tokenRequest)
        localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(accessTokenResponse.accessToken))
      }
    } catch (error) {
      console.error(error)
    } finally {
      localStorage.setItem("loginResponse", JSON.stringify(response))
      localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(accessTokenResponse))
    }
  },
  async msalLogin() {
    msalInstance.loginRedirect()
  },

  async getAccessToken({ commit }) {
    let request = {
      scopes: ["https://graph.microsoft.com/.default"],
      account: state.azureUser,
    }
    let tokenResponse = await msalInstance.acquireTokenSilent(request).catch((error) => {
      console.warn(error)
    })
    commit("setAccessToken", tokenResponse.accessToken)
  },

  async getMsAccount({ commit }) {
    const headers = new Headers()
    const bearer = state.msAccessToken
    headers.append("Authorization", bearer)

    const ms_session = axios.create({
      headers: {
        Authorization: bearer,
      },
    })
    await ms_session.get("https://graph.microsoft.com/v1.0/me").then((response) => {
      commit("SET_AZURE_USER", response.data)
    })
  },

  async setAzureAccount({ commit }, payload) {
    commit("SET_AZURE_USER", payload)
  },

  async getToDoLists({ commit }) {
    await django_session.get("erp/user/to-do-lists/").then((response) => {
      // console.log("Get To Do Lists", response.data.value)
      let payload = response.data.value
      commit("setUserToDoLists", payload)
    })
  },

  async hubLogin({ dispatch, commit }) {
    await dispatch("getAccessToken")
    await dispatch("getMsAccount")

    await django_session
      .post("erp/user/login/", {
        azure_client_id: state.azureUser.id,
        azure_token: state.msAccessToken,
        account: state.account,
      })
      .then((response) => {
        commit("SET_USER", response.data)
        commit("SET_AUTHENTICATED", true)
      })
      .catch((error) => {
        console.log(error)
        commit("SET_AUTHENTICATED", false)
        commit("SET_LOGIN_ERROR", true)
      })
  },

  async accountGrab({ commit }) {
    const accounts = msalInstance.getAllAccounts()
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
      commit("SET_AZURE_USER", accounts[0])
      console.log("Account Grab", accounts[0])
    } else {
      console.log("No Accounts")
    }
  },

  setAuthentication({ commit }, payload) {
    commit("SET_AUTHENTICATED", payload)
  },

  setUser({ commit }, payload) {
    commit("SET_USER", payload)
  },

  updateUser({ commit }, payload) {
    commit("UPDATE_USER", payload)
  },

  async logout({ commit }) {
    try {
      await django_session.post("erp/user/logout/")
      commit("LOGOUT")
      commit("REMOVE_TOKEN")
      commit("SET_AUTHENTICATED", false)
      commit("SET_LOGIN_ERROR", null)
      localStorage.removeItem("loginResponse")
      localStorage.removeItem("MCP_AUTHENTICATED_USER")
      localStorage.removeItem("GEM_TOKEN")
      localStorage.removeItem(TOKEN_STORAGE_KEY)
      console.log("Logout Redirect")
    } catch (error) {
      console.error("Logout failed", error)
    } finally {
      // Any cleanup or final steps go here
      await msalInstance.logoutRedirect({ logoutHint: state.loginHint })
    }
  },
}

const mutations = {
  setAccessToken(state, token) {
    state.msAccessToken = token
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token))
  },

  loginResponse(state, payload) {
    state.loginResponse = payload
  },

  refreshAccessToken(state, payload) {
    let completeToken = "Bearer " + payload.accessToken
    state.account = payload.account
    state.msAccessToken = completeToken
    state.user.azure_token = completeToken
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(completeToken))
  },

  SET_USER(state, payload) {
    state.user = payload
    state.user_id = payload.azure_client_id
    state.user.full_name = state.user.first_name + " " + state.user.last_name
    let get_roles = []
    for (var a = 0; a < payload.user_roles.length; a++) {
      django_session.get("erp/user/role/" + payload.user_roles[a].user_role_id + "/").then((response) => {
        let role_permissions = response.data.permissions
        for (var b = 0; b < role_permissions.length; b++) {
          if (!state.permissions.includes(role_permissions[b].user_permission_description))
            state.permissions.push(role_permissions[b].user_permission_description)
        }
      })
      get_roles.push(payload.user_roles[a].user_role_code)
    }
    state.roles = get_roles
    delete state.user.user_roles
    localStorage.setItem("MCP_AUTHENTICATED_USER", JSON.stringify(payload))
    localStorage.setItem("GEM_TOKEN", JSON.stringify(payload.gem_token))
  },

  setUserToDoLists(state, payload) {
    state.outlookToDoLists = payload
  },

  SET_AZURE_USER(state, payload) {
    state.azureUser = payload
  },

  SET_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload
  },

  SET_LOGIN_ERROR(state, payload) {
    state.loginError = payload
  },

  UPDATE_USER(state, payload) {
    state.user.default_to_do_list = payload.default_to_do_list
  },

  LOGOUT(state) {
    state.authenticating = false
    state.loginError = null
    state.user = {}
    state.account = {}
    state.user_id = null
    state.user.azure_client_id = null
    state.roles = []
    state.permissions = []
  },

  REMOVE_TOKEN(state) {
    localStorage.removeItem("MCP_AUTHENTICATED_USER")
    localStorage.removeItem("GEM_TOKEN")
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    state.msAccessToken = ""
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
